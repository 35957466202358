import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Menu } from '@melio/billpay-design-system';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getOrgId } from 'src/redux/user/selectors';
import { EmptyMethodCell } from 'src/billpay/qbdt/pages/batch-pay/table/components/EmptyMethodCell';
import { DeliveryMethodMenu } from 'src/billpay/qbdt/pages/batch-pay/table/components/DeliveryMethodCell/DeliveryMethodMenu';
import useGetValidFundingSources from 'src/billpay/qbdt/pages/batch-pay/table/hooks/useGetValidFundingSources';
import batchLocations from 'src/billpay/qbdt/pages/batch-pay/locations';
import { DeliveryMethodType } from 'src/utils/types';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types/store-types';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import analytics from 'src/services/analytics';
import { DELIVERY_TYPE } from 'src/utils/consts';
import { isSharedVendor } from 'src/pages/vendor/utils';
import { isEligibleToReceiveVirtualCard } from 'src/pages/vendor/virtual-delivery-method/utils';
import { useCreateBatchPayments } from '../../../hooks/useCreateBatchPayments';
import { getBillIdsFromBatchItem } from '../../../utils';
import { SelectedDeliveryMethodLabel } from '../../../components/deliveryMethod/SelectedDeliveryMethodLabel';

type DeliveryMethodCellProps = {
  batchItemId: string;
  payment: BatchPaymentType;
  dueDate: Date;
  isScheduledDateSelectedByUser: boolean;
};

export const DeliveryMethodCell = ({
  batchItemId,
  payment,
  dueDate,
  isScheduledDateSelectedByUser,
}: DeliveryMethodCellProps) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const history = useHistory();

  const { deliveryMethods, ownedById, ...vendor } = useSelector(vendorsStore.selectors.fetch.byId(payment?.vendorId));

  const isVendorShared = isSharedVendor(vendor);
  const isOwnedVendor = !!ownedById;

  const {
    createBatchPaymentData: { error },
  } = useCreateBatchPayments();

  const allBillsReady = useSelector(regularBatchPaymentsStore.selectors.allBillsReady);

  const batchItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchItemId));

  const { scheduledDate, fundingSourceId, amount, deliveryMethodId, vendorId } = payment;

  const [validFundingSources] = useGetValidFundingSources();

  const currentFundingSource = validFundingSources.find((fs) => fs.id === fundingSourceId);

  let supportedDeliveryMethods = deliveryMethods;
  if (!isEligibleToReceiveVirtualCard(currentFundingSource)) {
    supportedDeliveryMethods = deliveryMethods.filter((dm) => dm.deliveryType !== DELIVERY_TYPE.VIRTUAL_CARD);
  }

  if (!currentFundingSource?.isVerified) {
    supportedDeliveryMethods = supportedDeliveryMethods.filter((dm) => dm.deliveryType !== DELIVERY_TYPE.VIRTUAL);
  }

  const isError = error?.validationErrors?.[payment.bill.id] === 'deliveryMethodMissed' && !allBillsReady;

  const commonAnalyticsProps = {
    fromTable: 'yes',
    billIds: getBillIdsFromBatchItem(batchItem),
    vendorId,
    payBillFlowUuid: batchItem?.payBillFlowUUID,
  };

  const onChangeDeliveryMethod = async (selectedDeliveryMethod: DeliveryMethodType) => {
    if (deliveryMethodId === selectedDeliveryMethod.id) {
      return;
    }

    const isUnitaleral = selectedDeliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL;
    analytics.trackAction('ChangeDeliveryMethod', {
      ...commonAnalyticsProps,
      deliveryMethodId: selectedDeliveryMethod.id,
      deliveryMethodType: selectedDeliveryMethod.deliveryType,
      virtual: isUnitaleral,
    });

    if (!fundingSourceId) {
      actions.updatePayments.setPaymentDeliveryMethod({
        deliveryMethod: selectedDeliveryMethod,
        batchItemId,
      });

      return;
    }

    const paramsForUpdate = {
      batchItemId,
      orgId,
      deductionDate: scheduledDate,
      deliveryMethodId: selectedDeliveryMethod.id,
      fundingSourceId,
      amount,
      deliveryMethod: selectedDeliveryMethod,
      payBillFlowUUID: batchItem.payBillFlowUUID,
      createOrigin: batchItem.payment.createOrigin,
      // we want to reset delivery speed cell on delivery method change.
      deliveryPreference: null,
    };

    if (isScheduledDateSelectedByUser) {
      await actions.updatePaymentsDeliveryOptions(paramsForUpdate);

      return;
    }

    await actions.updatePaymentsDeductionDatesByDueDate({
      ...paramsForUpdate,
      dueDate,
      scheduledDate: null,
    });
  };

  const onAddDeliveryMethod = () => {
    analytics.trackAction('AddDeliveryMethodClick', {
      ...commonAnalyticsProps,
    });

    history.push(
      generatePath(batchLocations.addDeliveryMethod, {
        orgId,
      }),
      { batchItemId }
    );
  };

  const currentDeliveryMethod = useMemo(() => supportedDeliveryMethods?.find((dm) => dm.id === deliveryMethodId), [
    supportedDeliveryMethods,
    deliveryMethodId,
  ]);

  const handleDropdownOpen = () => {
    analytics.trackAction('open-select-delivery-method', {
      ...commonAnalyticsProps,
    });
  };
  const canNotChangeDeliveryMethod =
    isVendorShared ||
    isOwnedVendor ||
    currentDeliveryMethod?.deliveryType === DELIVERY_TYPE.RPPS ||
    currentDeliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

  if (canNotChangeDeliveryMethod) {
    return <SelectedDeliveryMethodLabel deliveryMethod={currentDeliveryMethod} />;
  }

  return (
    <Menu autoSelect={false} isLazy autoWidth size="sm" onOpen={handleDropdownOpen}>
      {({ isOpen }) => (
        <>
          {isEmpty(supportedDeliveryMethods) ? (
            <EmptyMethodCell onAddDeliveryMethod={onAddDeliveryMethod} testId={`add-delivery-method-${batchItemId}`} />
          ) : (
            <DeliveryMethodMenu
              isError={isError}
              currentDeliveryMethod={currentDeliveryMethod}
              deliveryMethods={supportedDeliveryMethods}
              currentDeliveryMethodId={deliveryMethodId}
              onChangeDeliveryMethod={onChangeDeliveryMethod}
              onAddDeliveryMethod={onAddDeliveryMethod}
              isOpen={isOpen}
              listTestId={`delivery-method-menu-list-${batchItemId}`}
              addDeliveryMethodTestId={`delivery-method-menu-list-add-delivery-method-${batchItemId}`}
            />
          )}
        </>
      )}
    </Menu>
  );
};
